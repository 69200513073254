@import './theme.scss';


.main-container {
    @include themed() {
        .row.messages-list {
            height: 100%;
            width: 100%;
            ul.MuiList-root {
                padding-top: 0;
                height: 73vh;
                overflow-y: auto;
                overflow-x: hidden;
                padding-right: 15px;
                li.messages-list-item {
                    margin: 10px 5px;
                    border: none;
                    border-radius: 25px;
                    &:last-child {
                        margin-bottom: 25px;
                    }
                    &.read {
                        .message-left {
                            .message-heading {
                                color: t('text');
                                font-weight: 100;
                            }
                            .message-overflow {
                                color: t('text');
                                font-weight: 100;
                                width: 75%;
                            }
                            .new-message-icon {
                                display: none;
                            }
                        }
                    }
                    &.unread {
                        .message-left {
                            .message-heading {
                                color: t('unreaded');
                                font-weight: 800;
                            }
                            .message-container {
                                position: relative;
                                .message-overflow {
                                    color: t('unreaded');
                                    font-weight: 800;
                                    width: 75%;
                                }
                                .new-message-icon {
                                    position: absolute;
                                    color: t('unreaded');
                                    right: 0;
                                    top: 0;
                                }
                            }
                        }
                    }
                    div.list-item-button {
                        padding-right: 15px;
                        width: 100%;
                        overflow: hidden;
                        background-color: t('bg');
                        border-radius: 25px;
                        box-shadow: t('box-shadow');
                        .message-heading {
                            font-family: 'Karla';
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            align-items: center;
                            text-align: left;
                            letter-spacing: 0px;
                            color: t('inbox-text-color');
                            opacity: 0.85;
                            padding: 0 10px;
                            p, .d-flex {
                                width: 100%;
                                margin: 0 0 5px;
                            }
                        }

                        .message-heading + .message-overflow {
                            display: none;
                        }

                        .message-overflow {
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            color: t('inbox-text-color');
                        }
                        &.active {
                            background-color: t('logo-bg');
                            .message-left {
                                .message {
                                    &:first-child p {
                                        display: none;
                                    }
                                }
                                .message {
                                    margin: 0;
                                    height: 40px;
                                }
                            }
                        }
                    }
                    .time-stamp {
                        font-size: 10px;
                        font-style: italic;
                        white-space: nowrap;
                    }
                }
            }
        }

        .message-placeholder {
            height: 50vh;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
        }

        .pagination {
            justify-content: space-between;
            align-items: center;
            padding: 5px 0;
            button.MuiIconButton-root {
                padding: 0;
                color: t(text)
            }
        }
        .spinner {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            svg {
                color: t('text');
            }
        }
        .row.messages-list .MuiListItemSecondaryAction-root,
        .MuiListItemSecondaryAction-root .MuiButtonBase-root,
        .MuiListItemSecondaryAction-root .MuiButtonBase-root > * {
            position: absolute;
        }

        .row.messages-list .MuiListItemSecondaryAction-root {
            top: 15px;
            right: 25px;
        }

        .clouds {
            margin-top: 20px;
        }

        .cloudOne {
            top: 319px;
            left: 576px;
            width: 131px;
            height: 94px;
            margin-bottom: -30px;
            margin-left: 113px;
        }

        .cloudTwo {
            top: 392px;
            left: 724px;
            width: 93px;
            height: 67px;
            margin-left: 262px;
            margin-bottom: 3px;
        }

        .cloudThree {
            top: 457px;
            left: 626px;
            width: 63px;
            height: 45px;
            margin-bottom: -20px;
            margin-left: 163px;
        }

        .cloudText {
            top: 556px;
            left: 529px;
            width: 354px;
            height: 71px;
            margin-top: 70px;
            margin-left: 50px;
            text-align: center;
            letter-spacing: 0px;
            color: #78C3FB;
            opacity: 1;
        }

        .row.messages-list .message-right {
            width: 66%;
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            overflow-y: auto;
            border-bottom-right-radius: 65px;
            background-color: t('bg');
            z-index: 1;
            .message-right--container {
                position: relative;
                height: 100%;
                .message-heading {
                    padding: 10px 15px;
                    font-family: 'Karla';
                }
                .message {
                    padding: 10px 15px;
                    height: calc(100% - 320px);
                    overflow: auto;
                }
                .address {
                    display: flex;
                    button {
                        padding: 0 5px;
                    }
                    p {
                        margin-bottom: 0 !important;
                    }
                }
                .form-container{
                    position: sticky;
                    bottom: 0;
                    min-height: auto;
                    margin-bottom: 0;
                    border-radius: 0;
                    &.content-box {
                        margin: 0;
                    }
                    form {
                        .wrapper {
                            margin-bottom: 10px;
                            .rdw-editor-toolbar.toolbar {
                                display: none;
                            }
                            .editor {
                                min-height: 150px;
                                height: 150px;
                                overflow-y: auto;
                                .rdw-left-aligned-block {
                                    display: none;
                                }
                                .DraftEditor-root {
                                    height: 85%;
                                }
                                span {
                                    background-color: transparent;
                                }
                            }
                        }
                        .send-btn {
                            margin-bottom: 0;
                        }
                    }
                }

            }
            &.full-height {
                .message {
                    height: calc(100% - 100px);
                }
            }
            &.inbox {
                .form-bottom .send-btn {
                    top: 0;
                    z-index: 9999;
                }

                .form-container {
                    padding: 0 15px 50px;
                }
            }
        }
    }
}

@media (min-width: 769px) {
    .pagination {
        max-width: 1080px;
        margin: 0 auto;
    }
    ul.MuiList-root {
        padding-top: 55px;
    }
    .message-left {
        overflow: hidden;
        margin-left: 3%;
    }
}