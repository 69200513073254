// @import './theme.scss';

// .theme .app-container header .main-menu--right form {
//     margin: 0 400px 0 0;
//     padding: 0 5px 0 15px;
//     border: 1px solid #ccc;
// }

// .theme .app-container header .main-menu--right form input {
//     font-size: 12px;
//     margin: 2px 0 0;
// }

// .theme .app-container header .main-menu--right > button:last-child {
//     margin: 0 100px 0 0;   
// }

// .theme .app-container header .main-menu--right button {
//     border-color: #ccc;
//     padding: 3px 10px;
// }

// .theme .main-container .pagination {
//     margin-bottom: 10px;
// }

// .list-item-button {
//     position: static;
// }

// .row.messages-list .message-left * {
//     background-color: transparent !important;
//     font-size: 12px !important;
// }

// .list-item-button.active {
//     background-color: #e1f1f3;
// }



// .intro-box {
//     border-left: 1px solid #ccc;
//     border-top: 1px solid #ccc;
//     padding: 10px 15px;
// }

// .sent.intro-box {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     div {
//         text-align: center;
//     }
//     img {
//         max-width: 175px;
//     }
//     p {
//         color: #ccc;
//     }
// }

// .list-item.send-item {
//     padding: 15px;
//     display: flex;
//     flex-direction: row-reverse;
//     background-color: #e1f1f3;
//     .MuiListItemText-root.text {
//         text-align: right;
//         .MuiTypography-root {
//             font-size: 16px;
//         }
//     }
//     .MuiListItemIcon-root.icon {
//         justify-content: center;
//     }
// }

// .modal-box {
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//     width: 400px;
//     background-color: #fff;
//     border: 1px solid #ccc;
//     box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 
//             0px 2px 2px 0px rgb(0 0 0 / 14%), 
//             0px 1px 5px 0px rgb(0 0 0 / 12%);
//     padding: 15px;
// }


// .side-menu--bottom .content a:first-child {
//     display: flex;
// }

// .side-menu--bottom .content a {
//     padding: 5px 0;
//     color: #000;
//     font-size: 12px;
// }

// .row.contact-item {
//     width: 100%;
//     margin-left: 0;
//     padding: 0;
//     .text {
//         span {
//             overflow: hidden;
//             text-overflow: ellipsis;
//         }
//     }
//     .buttons {
//         display: flex;
//         justify-content: flex-end;
//         svg {
//             font-size: 12px;
//         }
//     }
// }

@media (max-width: 767px) {
	.message-right,
	.intro-box,
	.side-menu--bottom .contacts,
	.side-menu--bottom .content {
		display: none;
	}
	.theme .app-container header form {
		top: 75px;
		width: calc( 100% - 40px);
		left: 20px;
	}
	.list-item.send-item {
		padding: 8px 16px;
		background-color: transparent;
	}
}

@media (min-width: 769px) {
	.row.messages-list {
		position: relative;
		& * {
			position: static;
		}
		.intro-box {
			background: url(../photos/no-message.svg) no-repeat 98% 98%;
			background-size: 250px;
			position: relative;
		}
		.corner-image {
			background: url(../photos/icons/corner.svg) no-repeat 98% 98%;
			background-size: 40%;
		}
		.open-message {
			width: 170px;
			text-align: center;
			color: #809BCE;
			opacity: 0.8;
			position: absolute;
			bottom: 20%;
			right: 8%;
		}
	}
}