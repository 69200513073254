@import './theme.scss';

.app-container {
    @include themed() {
        .app-bar--top {
            position: relative;
            margin: 30px 45px 15px 15px;
            height: 5%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            .main-menu--right {
                position: absolute;
                right: 0;
                .MuiButton-contained {
                    font-family: 'Karla';
                    padding: 5px 25px;
                    border-radius: 25px;
                    background-color: t('bg');
                    color: t('text');
                    border: 1px solid t('text');
                    svg {
                        width: 18px;
                        height: 18px;
                    }
                }
                button {
                    margin: 0 5px;
                }
            }
            .main-menu--left {
                position: absolute;
                left: 0;
            }
        }
    }
}

.popper {
    z-index: 1203;
    width: 250px;
    padding: 0 15px 15px 15px;
    margin-top: 15px !important;
    border-radius: 25px;
    &.classic {
        background-color: #ffeee8;
        box-shadow: 0px 3px 15px #1A152054;
        ul {
            margin-top: 15px;
            list-style: none;
            padding-left: 0;
            color: #19141f;
        }
    }
    &.dark {
        background-color: #2b2235;
        box-shadow: 0px 3px 15px #0f0b1373;
        ul {
            margin-top: 15px;
            list-style: none;
            padding-left: 0;
            color: #fff;
        }
    }
    background-color: transparent;
    ul.MuiList-root{
        padding-top: 0;
    }
    .theme-select {
        .form-select {
            font-size: 14px;
            border: 1px solid #000;
            padding: 0 15px;
            background-position: right 0.4rem center;
        }
    }
    .logout-btn, .add-token-btn, .copy-wallet-btn {
        margin-top: 15px;
        border-radius: 25px;
        width: 100%;
        font-size: 12px;
        color: #fff;
        background-color: #d34832;
        border: none;
        padding: 5px 25px;
        &:hover {
            background-color: #d34832;
        }
    }
    .add-token-btn {
        background-color: #7f9acd;
        &:hover {
            background-color: #7f9acd;
        }
    }
    .copy-wallet-btn {
        background-color: #1dd882;
        &:hover {
            background-color: #1dd882;
        }
    }
}